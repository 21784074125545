import { createSlice } from "@reduxjs/toolkit";

export const quizSlise = createSlice({
  name: "quiz",
  initialState: {
    step: 1,
    answers: [],
    questions: "",
    userProfile: "",
  },
  reducers: {
    quizStart: (state) => {
      state.step = 2;
    },
    quizSubmit: (state, action) => {
      state.step = 3;
      state.answers = [...action.payload?.answers];
    },
    quizSave: (state, action) => {
      state.answers = [...action.payload?.answers];
    },
    quizReset: (state) => {
      state.step = 1;
      state.answers = [];
      state.questions = "";
      state.userProfile = "";
    },
    saveQuestion: (state, action) => {
      state.step = 1;
      state.answers = [];
      state.questions = action.payload?.questions;
      state.userProfile = action.payload?.userProfile;
    },
  },
});

export const { quizStart, quizSubmit, quizSave, quizReset, saveQuestion } =
  quizSlise.actions;

export default quizSlise.reducer;
