import "./App.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from "./components/Home";
import Question from "./components/Question";
import Result from "./components/Result";
import Header from "./components/Header";
import LandingPageHeader from "./components/HeaderLanding";
import Footer from "./components/Footer";
import LandingPage from "./components/LandingPage";
// import Login from "./components/Login";
// import { auth } from './consts/firebase';
// import { useAuthState } from 'react-firebase-hooks/auth';

const options = [
  "Very Unlikely",
  "Unlikely",
  "Neutral",
  "Likely",
  "Very Likely",
];

// const subjectMajorList = [
//   "B.Sc in Biochemistry and Biotechnology",
//   "B.Sc in Civil & Environmental Engineering (CEE)",
//   "B.Sc in Environmental Science and Management",
//   "B.Sc. in Genetic Engineering and Biotechnology",
//   "B.Sc. in Multimedia & Creative Technology (MCT)",
//   "B.SS in Global Studies and Governance (GSG)",
//   "B.SS in Media & Communication",
//   "Bachelor of Architecture",
//   "Bachelor of Arts in English",
//   "Bachelor of Business Administration (BBA)",
//   "Bachelor of Innovation & Entrepreneurship (BE)",
//   "Bachelor of Laws , LL. B. (Hons.)",
//   "Bachelor of Pharmacy (Hons)",
//   "Bachelor of Public Health",
//   "Bachelor of Science in Applied Physics & Electronics",
//   "Bachelor of Science in Biotechnology",
//   "Bachelor of Science in Computer Science",
//   "Bachelor of Science in Computer Science & Engineering",
//   "Bachelor of Science in Electrical And Electronic Engineering",
//   "Bachelor of Science in Electronic And Telecom Engineering",
//   "Bachelor of Science in Mathematics",
//   "Bachelor of Science in Microbiology",
//   "Bachelor of Science In Physics",
//   "Bachelor of Social Science in Economics",
//   "Bachelor of Social Sciences in Anthropology",
//   "B.A in English Language Teaching (ELT)",
//   "B.S. in Applied Statistics",
//   "B.S.S. in Information Studies and Library Management (ISLM)",
//   "Bachelor of Business Studies (BBS)",
//   "Bachelor of Tourism & Hospitality Management (BTHM)"
// ];

function App() {
  // const [user] = useAuthState(auth);
  const step = useSelector((state) => state.step);
  //const location = useLocation();
  return (
    // user ? (
    //   <div className="main-body">
    //     {user && step === 1 && <Home />}
    //     {user && step === 2 && <Question options={options} />}
    //     {user && step === 3 && <Result options={options} />}
    //     {!user && <Login />}
    //   </div>
    // ) : <Login />

    // <div className="flex flex-col min-h-screen">
    //   {/* <Header /> */}
    //   {/* <LandingPageHeader/> */}

    //   <main className="flex-grow">
    //     {/* {step === 1 && <LandingPage />} */}
    //     {step === 1 && <Home />}
    //     {step === 2 && <Question options={options} />}
    //     {step === 3 && <Result options={options} />}
    //   </main>
    //   {/* <Footer/> */}
    // </div>
    // <Router>
    //   {/* <Header_Landing /> */}
    //   {location.pathname === '/' ? <LandingPageHeader /> : <Header />}
    //   <Routes>
    //     <Route path="/" element={<LandingPage />} />
    //     <Route path="/home" element={<Home />} />
    //     <Route path="/question" element={<Question />} />
    //     <Route path="/result" element={<Result />} />
    //     {/* <Route path="/login" element={<Login />} /> */}
    //   </Routes>
    //   <Footer />
    // </Router>
    <Router>
      <Routes>
        <Route path="/*" element={<Page />} />
      </Routes>
    </Router>
  );
}

// function Page() {
//   const location = useLocation();
//   const step = useSelector((state) => state.step);

//   return (
//     <>
//       {location.pathname === '/' ? <LandingPageHeader /> : <Header />}
//       <Routes>
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/home" element={<Home />} />
//         <Route path="/question" element={<Question />} render={() => step === 2 && <Question options={options}/>} />
//         <Route path="/result" element={<Result />} />
//         {/* <Route path="/login" element={<Login />} /> */}
//       </Routes>
//       <Footer />
//     </>
//   );
// }
function Page() {
  const location = useLocation();
  const step = useSelector((state) => state.step);

  return (
    <>
      {location.pathname === '/' ? <LandingPageHeader /> : <Header />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/question" element={step === 2 ? <Question options={options} /> : null} />
        <Route path="/result" element={step === 3 ? <Result options={options} /> : null} />
        {/* <Route path="/login" element={<Login />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
