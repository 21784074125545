import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import logo from '../assets/CareerKey_Logo_Footer.png';
import 'react-icons/bs';
import { BsTelephone, BsFacebook, BsInstagram, BsTwitter, BsPinterest, BsWhatsapp } from 'react-icons/bs';
import { IoMailOutline } from 'react-icons/io5';

const Footer = () => {
  const step = useSelector((state) => state.step);
  return (
    <footer className="bg-[#231842] text-white py-6">
      <div className="container mx-auto flex flex-wrap justify-center items-center lg:justify-between lg:items-start">
        <div className="footer-logo pl-0 lg:pl-20 w-full lg:w-auto text-center lg:text-left mb-8 lg:mb-0">
          <img src={logo} alt="Logo" className="max-w-24 mx-auto lg:mx-0" />
          <ul>
            <li>
              <br />This is a beautiful template! The custom <br /> page builder is definitely
              most intuitive. <br /> This is a beautiful template! The custom <br /> page builder is definitely
              most intuitive. <br />
            </li>
            <li>
              <p className="text-xs mt-8">© {new Date().getFullYear()} Company Name. All Rights Reserved.</p>
            </li>
          </ul>
        </div>

        <div className="footer-content flex-1 text-center lg:px-60 mb-8 lg:mb-0">
          <div className="h-full flex flex-col justify-center items-center lg:items-start">
            <h4 className="mb-4 font-bold text-xl">Services</h4>
            <ul className="text-left space-y-2">
              <li className="flex items-center justify-center lg:justify-start">
                {step !== 2 ? (
                  <Link to="/home" className="hover:underline">Career Test</Link>
                ) : (
                  <span className="hover:underline">Career Test</span>
                )}
              </li>
              <li className="flex items-center justify-center lg:justify-start">Mentorship</li>
              <li className="flex items-center justify-center lg:justify-start">Psychometric Test</li>
              <li className="flex items-center justify-center lg:justify-start">Counselling</li>
            </ul>
          </div>
        </div>


        <div className="footer-section w-full lg:w-auto text-center lg:text-left">
          <h4 className="mb-4 font-bold text-xl">Contact Us</h4>
          <ul className="text-left space-y-2">
            <li className="flex items-center justify-center lg:justify-start">59/7, Panthapath, Dhaka 1215, Bangladesh </li>
            <li className="flex items-center justify-center lg:justify-start">
              <BsTelephone /><span className="ml-2">+8801551805954</span>
            </li>
            <li className="flex items-center justify-center lg:justify-start">
              <IoMailOutline /><span className="ml-2">careerkey@gmail.com</span>
            </li>

            <li className="flex items-center justify-center lg:justify-start">
              <div className="bg-white p-2 rounded-full text-white inline-flex items-center justify-center">
                <BsFacebook className="text-violet-950" />
              </div>
              <span className="ml-4">
                <div className="bg-white p-2 rounded-full text-white inline-flex items-center justify-center">
                  <BsInstagram className="text-violet-950" />
                </div>
              </span>
              <span className="ml-4">
                <div className="bg-white p-2 rounded-full text-white inline-flex items-center justify-center">
                  <BsTwitter className="text-violet-950" />
                </div>
              </span>
              <span className="ml-4">
                <div className="bg-white p-2 rounded-full text-white inline-flex items-center justify-center">
                  <BsWhatsapp className="text-violet-950" />
                </div>
              </span>
              <span className="ml-4">
                <div className="bg-white p-2 rounded-full text-white inline-flex items-center justify-center">
                  < BsPinterest className="text-violet-950" />
                </div>
              </span>
            </li>
            <li>
              <p className="text-xs mt-8 text-right font-bold">Powered by CareerKey</p>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="flex justify-between items-center mt-8 mx-20 flex-col lg:flex-row">
        <div>
          <p className="text-xs">© {new Date().getFullYear()} Company Name. All Rights Reserved.</p>
        </div>
        <div>
          <p className="text-xs">Powered by CareerKey</p>
        </div>
      </div> */}

    </footer>
  );
};

export default Footer;
