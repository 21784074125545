import React from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { quizReset, quizSave, quizSubmit } from "../redux/quizSlice";
import { isJsonString } from "../consts/utils";

const Question = ({ options }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const answers = useSelector((state) => state.answers);
  const questionList = useSelector((state) => state.questions);
  // const Questions = isJsonString(questionList) ? JSON.parse(questionList) : [];
  const Questions = questionList;

  const handleSelect = (questionIndex, answerIndex) => {
    let ans = [...answers];
    ans[questionIndex] = {
      q: questionIndex,
      a: answerIndex,
    };

    dispatch(
      quizSave({
        answers: ans,
      })
    );
  };

  const handleSubmit = () => {
    dispatch(
      quizSubmit({
        answers: [...answers],
      })
    );
    navigate('/result');
  };

  const handleReset = () => {
    dispatch(quizReset());
  };

  const QuestionItem = ({ index, item }) => {
    return (
      <div>
        <div style={{ marginBottom: "2rem", backgroundColor: "#FFF1F2" }}>
          <p
            style={{
              color: "#474747",
              fontWeight: "bold",
              fontFamily: "Averta,sans-serif,sans-serif",
              margin: "0 0 1rem 0",
            }}
          >
            {index + 1}. {item.question}
          </p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {options.map((optionItem, optionIndex) => (
              <OptionItem
                key={optionIndex}
                index={optionIndex}
                item={optionItem}
                questionIndex={index}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const OptionItem = ({ index, item, questionIndex }) => {
    const selected = answers[questionIndex]
      ? answers[questionIndex].a === index
      : false;
    const isYes = index === options.length - 1;
    const isNo = index === 0;
    return (
      <div
        title={options[index]}
        className={`${selected ? "option-item-marked" : "option-item"}`}
        style={{
          borderRadius: 10,
          marginBottom: 4,
          paddingLeft: 10,
          paddingRight: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // boxShadow: "0 0 3px 0 rgba(0,0,0,.07),0 2px 2px 0 rgba(0,0,0,.04)",
          border: "2px solid white",
          // background: "white"
        }}
        onClick={(event) => {
          //event.preventDefault(); 
          handleSelect(questionIndex, index);
          //return false; 
        }}
      // onMouseEnter={(event) => {
      //   const tooltip = document.createElement("div");
      //   tooltip.style.position = "absolute";
      //   const rect = event.target.getBoundingClientRect();
      //   tooltip.style.top = `${rect.top + window.scrollY + event.target.offsetHeight + 5}px`;
      //   tooltip.style.left = `${rect.left + window.scrollX}px`;
      //   tooltip.style.padding = "5px";
      //   tooltip.style.borderRadius = "5px";
      //   tooltip.style.backgroundColor = "#333";
      //   tooltip.style.color = "#fff";
      //   tooltip.id = "my-tooltip";

      //   let tooltipString = "";

      //   if (index === 0){
      //     tooltipString = "Absolutely No";
      //   }else if (index === 1){
      //     tooltipString = "Maybe No";
      //   }else if (index === 2){
      //     tooltipString = "Neutral";
      //   }else if (index === 3){
      //     tooltipString = "Maybe Yes";
      //   }else if (index === 4){
      //     tooltipString = "Absolutely Yes";
      //   }else{
      //     tooltipString = ""
      //   }

      //   tooltip.innerText = tooltipString;
      //   document.body.appendChild(tooltip);
      // }}
      // onMouseLeave={() => {
      //   setTimeout(() => {
      //     const tooltip = document.getElementById("my-tooltip");
      //     document.body.removeChild(tooltip);
      //   }, 10);
      // }}
      >
        <p style={{ fontFamily: "Averta,sans-serif,sans-serif", paddingRight: 8 }}>
          {/* {item} */}
          {isNo ? "Unlikely" : ""}
        </p>
        <input
          type="radio"
          className="radioBtn"
          checked={selected}
          onChange={(event) => {
            event.preventDefault();
            event.nativeEvent.stopImmediatePropagation();
            handleSelect(questionIndex, index);
            return false;
          }}
          style={{ height: "20px", width: "20px" }}
        />
        {isYes ? <span style={{ fontFamily: "Averta,sans-serif,sans-serif", marginLeft: 10 }}>Likely</span> : null}
      </div>
    );
  };
  return (
    <div className="question-body">
      <div className="question-div">
        {Questions?.length > 0 &&
          Questions?.map((item, index) => (
            <QuestionItem key={index} index={index} item={item} />
          ))}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <button
            className="button"
            onClick={handleReset}
            style={{
              padding: "15px 30px 15px 30px",
              borderRadius: 7,
              borderWidth: 0,
              fontSize: 15,
              backgroundColor: "#e43755",
              cursor: "pointer",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Back
          </button> */}
          <button
            className="button"
            onClick={handleSubmit}
            style={{
              padding: "15px 30px 15px 30px",
              borderRadius: 7,
              backgroundColor: "green",
              color: "white",
              fontWeight: "bold",
              borderWidth: 0,
              fontSize: 15,
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Question;
