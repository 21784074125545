import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { quizReset } from "../redux/quizSlice";
import { CircularProgress } from "@material-ui/core";
import { isJsonString } from "../consts/utils";
import { OPEN_AI_URL } from "../consts/endPoints";
import Card from "./Card";
import database from '../consts/firebase';

const Result = ({ options }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const answers = useSelector((state) => state.answers);
  const questionList = useSelector((state) => state.questions);
  // const Questions = isJsonString(questionList) ? JSON.parse(questionList) : [];
  const Questions = questionList;
  const userProfile = useSelector((state) => state.userProfile);
  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState([]);
  const [rsecondsPassed, setrSecondsPassed] = useState(0);
  const [countdownMiddleTexthidden, setcountdownMiddleTextHidden] = useState(false);
  const [data, setData] = useState([]);

  const subjectMajorList = [
    "B.Sc in Biochemistry and Biotechnology",
    "B.Sc in Civil & Environmental Engineering (CEE)",
    "B.Sc in Environmental Science and Management",
    "B.Sc. in Genetic Engineering and Biotechnology",
    "B.Sc. in Multimedia & Creative Technology (MCT)",
    "B.SS in Global Studies and Governance (GSG)",
    "B.SS in Media & Communication",
    "Bachelor of Architecture",
    "Bachelor of Arts in English",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Innovation & Entrepreneurship (BE)",
    "Bachelor of Laws , LL. B. (Hons.)",
    "Bachelor of Pharmacy (Hons)",
    "Bachelor of Public Health",
    "Bachelor of Science in Applied Physics & Electronics",
    "Bachelor of Science in Biotechnology",
    "Bachelor of Science in Computer Science",
    "Bachelor of Science in Computer Science & Engineering",
    "Bachelor of Science in Electrical And Electronic Engineering",
    "Bachelor of Science in Electronic And Telecom Engineering",
    "Bachelor of Science in Mathematics",
    "Bachelor of Science in Microbiology",
    "Bachelor of Science In Physics",
    "Bachelor of Social Science in Economics",
    "Bachelor of Social Sciences in Anthropology",
    "B.A in English Language Teaching (ELT)",
    "B.S. in Applied Statistics",
    "B.S.S. in Information Studies and Library Management (ISLM)",
    "Bachelor of Business Studies (BBS)",
    "Bachelor of Tourism & Hospitality Management (BTHM)"
  ];

  const handleReset = () => {
    dispatch(quizReset());
    navigate('/')
  };

  const startrCountdown = () => {
    setInterval(() => {
      setrSecondsPassed(rsecondsPassed => rsecondsPassed + 1);
    }, 1000);
  }

  const stoprCountdown = () => {
    clearInterval(setrSecondsPassed(0));
  }


  async function handleSubmit(message) {
    setLoading(true);
    startrCountdown();
    try {
      const response = await fetch(OPEN_AI_URL, {
        method: "POST",
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `You are a highly intelligent future career selection tool.

              ### Instruction ###
              Prepare and rank a list of top 3 matching stable career path for a student based on user provided profile details and answers of the provided questions. Reply with only the answer in JSON array form and include no other commentary.
              
              ### Rules ###
              Follow these rules strictly:
              
              1. Career relevancy of Bangladeshi culture and job market must be required.

              2. Focus on the patterns of the student's responses, which can range from "Very Likely" to "Very Unlikely". Make career decisions based on which tasks the student likes most.

              3. Organize each career response into following paragraphs: Career name, Matching score, Character traits, Explanation, Subject Major.
                        
              4. Matching score: Calculate career matching score based on the provided user data and given Q&As. Find the relevancy with the career choice. Assign score values based on a the range from 0.0 to 1.0.

              5. Character traits: Write top 3 character traits for choosing a particular career in plain text and mention individual trait scores for this choice. Assign score values based on a the range from 0.0 to 1.0.
              
              6. Explanation: Deliberately explain why you think the career will be suitable for the student within 60 words.

              7. Subject Major: 
              
              - First, guess a subject major/degree/department yourself which maybe suitable to build your suggested career. Then map your choice with this degree list and mention only top matching 1 choice in plain text.\n Degree List:` + subjectMajorList.join('\n') +

                `\n- Second, briefly explain why those 2 subjects would be suitable for the student within 150 words.
              
              7. Strictly rename the JSON array as 'careerList', where each object have keys: careerOption, matchingScore, characterTraits, explanation, subjectMajor, majorExplanation and corresponding response as the value.
              `,
            },
            { role: "user", content: `${message}` },
          ],
        }),
      });
      const data = await response.json();
      const parsedData = data.choices[0].message.content;

      let parsedObject;
      if (Array.isArray(parsedData)) {
        parsedObject = { careerList: parsedData };
      } else {
        parsedObject = JSON.parse(parsedData);
      }
      // const parsedObject = JSON.parse(parsedData);
      // const careerArray = parsedObject.careerList;
      //setData(parsedData.careerList);
      console.log("parsed_data:" + parsedData);
      setSuggestion(parsedObject.careerList);

      /* userProfile data extration, Goal: save into firebase using Phone number of the user*/
      const userProfileArray = userProfile.split('\n').filter(Boolean);
      const userProfileObject = {};
      userProfileArray.forEach((item) => {
        const [key, value] = item.split(': ');
        userProfileObject[key] = value;
      });

      /*Now can use userProfileObject properties like userProfileObject["Name"]*/
      //console.log("rrName:" + userProfileObject["Name"]);
      // Push Function of firebase to save all user details, QA list and Career list data
      try {
        let QAList = ``;
        if (questionList !== "") {
          Questions?.forEach((item, index) => {
            QAList =
              QAList +
              `\n${index + 1}. ${item.question} \nAns: ${options[answers[index]?.a] ? options[answers[index]?.a] : ""
              } `;
          });
        }

        database.ref("users").child(userProfileObject["Phone"]).set({
          name: userProfileObject["Name"],
          phone: userProfileObject["Phone"],
          age: userProfileObject["Age"],
          class: userProfileObject["Class"],
          edu_grp: userProfileObject["Education Group"],
          likes: userProfileObject["Likes"],
          dislikes: userProfileObject["Dislikes"],
          llm_qalist: QAList,
          llm_careers: parsedObject.careerList
        });
        console.log("All data pushed to firebase successfully.");
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }

      stoprCountdown();
    } catch (error) {
      console.log(error);
      setLoading(false);
      // alert("Something went wrong!");
    }
  }

  useEffect(() => {
    //window.scrollTo(0, 0);

    if (questionList !== "") {
      let QAList = `### Question and Answers ###`;
      Questions?.forEach((item, index) => {
        QAList =
          QAList +
          `\n${index + 1}. ${item.question} \nAns: ${options[answers[index]?.a] ? options[answers[index]?.a] : ""
          } `;
      });

      const message = `### Student details ### \n${userProfile}\n${QAList}`;

      handleSubmit(message);
      console.log(message);
    }
  }, []);

  return (
    <div className="question-body">
      <div
        className="question-div"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {(loading && rsecondsPassed < 50) ? (
          // <p>Career options being prepared : {(rsecondsPassed / 25) * 100} %</p>
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <p className="text-xl font-bold text-center text-blue-600"> Career options being prepared : {Math.round((rsecondsPassed / 50) * 100)}% completed. </p>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-400">
              <div
                style={{ width: `${(Math.round(rsecondsPassed) / 50) * 100}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900"
              ></div>
            </div>
          </div>
        ) : (null)
        }
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              bottom: "50%",
              left: "50%",
              right: "50%",
            }}
          >
            <CircularProgress
              style={{
                height: "30px",
                width: "30px",
                margin: 0,
                padding: 0,
              }}
            />
          </div>
        ) : (
          <div>
            {/* <p style={{ whiteSpace: "pre-wrap", flex: 1 }}>{suggestion}</p> */}
            <div className="flex flex-wrap justify-center w-full">
              {/* <CardView suggestion={suggestion} /> */}
              {suggestion && suggestion.map((item, index) => (
                <Card key={item.careerOption} item={item} index={index} />
                // <CardView key={item.careerOption} item={item} />
              ))}
            </div>

            <div>
              <button
                className="button"
                onClick={handleReset}
                style={{
                  padding: "15px 30px 15px 30px",
                  marginTop: 8,
                  borderRadius: 7,
                  borderWidth: 0,
                  fontSize: 15,
                  backgroundColor: "green",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Home Page
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Result;
