import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { quizStart, saveQuestion } from "../redux/quizSlice";
import { OPEN_AI_URL } from "../consts/endPoints";
// import { auth } from './../consts/firebase';

import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


export const Home = () => {
  const dispatch = useDispatch();
  // Inside your component
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [updated, setUpdated] = useState(message);
  const [loading, setLoading] = useState(false);
  const [secondsPassed, setSecondsPassed] = useState(0);

  const [inputName, setInputName] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [inputAge, setInputAge] = useState('');
  const [inputClass, setInputClass] = useState('');
  const [inputLikes, setInputLikes] = useState('');
  const [inputDislikes, setInputDislikes] = useState('');
  const [groupSelected, setGroupSelection] = useState('');
  const [userData, setUserData] = useState('');
  const [tempData, setTempData] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const likeDislikeOptions = ["Math", "English", "Biology", "Medicine", "Computer", "IT", "Economics",
    "Social Science", "Programming", "Business", "Entrepreneurship", "Teaching", "Physics", "Creative Writing",
    "Electronics", "Law", "Journalism", "Genetics"];

  const [open, setOpenLikes] = useState(false); // state hook for dialog box
  const [checkedItems, setCheckedItems] = useState({}); // state hook for checked items

  const handleClickToOpenLikes = () => {
    setOpenLikes(true); // open dialog box
  };

  const handleToCloseLikes = () => {
    setOpenLikes(false); // close dialog box
    setInputLikes(Object.keys(checkedItems).filter((key) => checkedItems[key]).join(", ")); // update input value with checked items

  };

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    }); // update checked items
  };

  const [openDislikes, setOpenDislikes] = useState(false); // state hook for dislikes dialog box
  const [checkedDislikes, setCheckedDislikes] = useState({}); // state hook for checked dislikes items

  const handleClickToOpenDislikes = () => {
    setOpenDislikes(true); // open dislikes dialog box
  };

  const handleToCloseDislikes = () => {
    setOpenDislikes(false); // close dislikes dialog box
    setInputDislikes(Object.keys(checkedDislikes).filter((key) => checkedDislikes[key]).join(", ")); // update dislikes input value with checked dislikes items
  };

  const handleCheckboxChangeDislikes = (event) => {
    setCheckedDislikes({
      ...checkedDislikes,
      [event.target.name]: event.target.checked,
    }); // update checked dislikes items
  };

  const handleInputNameChange = (event) => {
    setInputName(event.target.value);
    if (event.target.value && inputPhone && inputAge && inputClass && groupSelected) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }
  const handleInputPhoneChange = (event) => {
    // const input_str = event.target.value;
    // const re = /^\+8801\d{9}$/;
    // if (re.test(input_str)) {
    //   setInputPhone(input_str);
    //   event.target.setCustomValidity('');
    // } else {
    //   event.target.setCustomValidity('Invalid phone number!');
    //   event.target.reportValidity();
    // }
    // setInputPhone(input_str);
    const input_str = event.target.value;
    const re = /^(\+880)?0?1\d{0,9}$/;
    const formatted_str = input_str.replace(/^(\+880)?0?1/, '+8801');
    if (re.test(formatted_str)) {
      setInputPhone(formatted_str);
      event.target.setCustomValidity('');
    } else {
      event.target.setCustomValidity('Invalid phone number!');
      event.target.reportValidity();
    }
    setInputPhone(formatted_str);
    if (event.target.value && inputName && inputAge && inputClass && groupSelected) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }
  const handleInputAgeChange = (event) => {
    setInputAge(event.target.value);
    if (event.target.value && inputName && inputPhone && inputClass && groupSelected) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }
  const handleInputClassChange = (event) => {
    setInputClass(event.target.value);
    if (event.target.value && inputName && inputPhone && inputAge && groupSelected) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }
  const handleInputLikesChange = (event) => {
    //setInputLikes(event.target.value);
    // if (event.target.value && inputName && inputPhone && inputAge && inputClass && inputDislikes && groupSelected) {
    //   setIsDisabled(false);
    // } else {
    //   setIsDisabled(true);
    // }
  }
  const handleInputDislikesChange = (event) => {
    // setInputDislikes(event.target.value);
    // if (event.target.value && inputName && inputPhone && inputAge && inputClass && inputLikes && groupSelected) {
    //   setIsDisabled(false);
    // } else {
    //   setIsDisabled(true);
    // }
  }
  const handleGroupSelectionChange = (event) => {
    setGroupSelection(event.target.value);
    if (event.target.value && inputName && inputPhone && inputAge && inputClass) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  const handleButtonSubmit = (event) => {
    event.preventDefault();
    setUserData('Name: ' + inputName + '\nAge: ' + inputAge + '\nClass: ' + inputClass + '\nEducation Group: ' + groupSelected + '\nLikes: ' + inputLikes + '\nDislikes: ' + inputDislikes);
    //alert(combinedString);
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSecondsPassed(secondsPassed => secondsPassed + 1);
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };

  // }, []);
  const startCountdown = () => {
    setInterval(() => {
      setSecondsPassed(secondsPassed => secondsPassed + 1);
    }, 1000);
  }

  // useEffect(() => {
  //   const startCountdown = setInterval(() => {

  //     if(secondsPassed < 5){
  //       setSecondsPassed(secondsPassed => secondsPassed + 1);
  //     } else {
  //       setSecondsPassed(secondsPassed => secondsPassed +8)

  //     }
  //   }, 1000);


  //   }, []);

  // const startCountdown = () => {
  //   setInterval(() => {
  //     setSecondsPassed(secondsPassed => secondsPassed + 1);
  //   }, 1000);
  // }
  const stopCountdown = () => {
    clearInterval(setSecondsPassed(0));
  }
  // const startTime = useRef(new Date());
  // const endTime = useRef(new Date());
  // const [elapsedTime, setElapsedTime] = useState();

  // const startCountdown = () => {
  //   startTime.current = new Date();
  //   setElapsedTime(Math.floor((startTime.current / 1000) % 60));
  // }
  // const endCountdown = () => {
  //   endTime.current = new Date();
  //   const et = (endTime.current - startTime.current) / 1000 // get the seconds
  //   setElapsedTime(et);
  //   console.log(et); // You cannot log elapsedTime here, because again, useState is async
  //  }

  const handleChange = (event) => {
    setMessage(event.target.value);
  };


  const handleSubmitButtonClick = () => {
    // 👇 "message" stores input field value
    //setUpdated(message);
    // const data =
    //   "Name: " +
    //   inputName +
    //   "\nAge: " +
    //   inputAge +
    //   "\nClass: " +
    //   inputClass +
    //   "\nEducation Group: " +
    //   groupSelected +
    //   "\nLikes: " +
    //   inputLikes +
    //   "\nDislikes: " +
    //   inputDislikes;

    setLoading(true);
    startCountdown();
    handleSubmit();
  };

  useEffect(() => {
    //handleSubmit();
    const data =
      "Name: " +
      inputName +
      "\nPhone: " +
      inputPhone +
      "\nAge: " +
      inputAge +
      "\nClass: " +
      inputClass +
      "\nEducation Group: " +
      groupSelected +
      "\nLikes: " +
      inputLikes +
      "\nDislikes: " +
      inputDislikes;

    setUserData(data);
  }, [inputName, inputPhone, inputAge, inputClass, groupSelected, inputLikes, inputDislikes]);

  async function handleSubmit() {
    try {
      const response = await fetch(OPEN_AI_URL, {
        method: "POST",
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                `You are a highly intelligent future career selection tool. 

                ### Instruction ###
                Prepare a list of 30 unique task questions which does not contain the word 'or' and must be suitable for Bangladeshi Grades 11-12 students to identify user career choice by following below rules. Reply with only the answer in JSON array form and include no other commentary.                
                
                ### Rules ###
                Follow these rules strictly:
                1. Make the questions format exactly like "Holland Code Job Aptitude Test".
                
                2. Each task question will be short, not greater than 30 characters and easily understandable to a high school student.
                               
                3. Task relevancy of Bangladeshi culture and job market is highly required.
                
                4. Refrain yourself from repeating same tasks or action, bring varieties of task from different education group. 
                
                5. Refrain yourself from being focused on only the student's likes and dislikes. Include out-of-the-box and exciting tasks, beyond the student's group or likes.
                
                6. The JSON array name is questionList, where each object have a key 'question' and generated question as the value.

                ### Valid and Invalid Question Examples ###
                - Do you like to come up with new ideas? // valid
                - I like to write stories from new ideas. // invalid (because starts with I instead of You)
                - Do you enjoy taking charge and coordinating activities to achieve goals? // valid
                - I enjoy taking risks to achieve my desired goals. // invalid (because starts with I instead of You)

                ### Question Format Examples ###
                - Do you find {..task..} useful? 
                - Do you love making {..task..}? 
                - How much you enjoy doing {..task..}?
                - Do you like {..task1..} and {..task2..}?
                - How interesting it is {..task..} for you?
                `,
            },
            { role: "user", content: `${userData}` },
          ],
        }),
      });

      const data = await response.json();
      setLoading(false);
      stopCountdown();


      // let start = Date.now();
      // handleSubmit();
      // let timeTaken = Date.now() - start;
      // console.log("Total time taken : " + elapsedTime.toString());

      const parsedData = data.choices[0].message.content; //got the json array from api response
      // const parsedArray = JSON.parse(parsedData);
      //console.log("parsedData" + parsedData);
      // const match = parsedData.match(/\[.*\]/);
      // if (match !== null) {
      //   const questionArray = JSON.parse(match[0]);
      //   console.log("questionArray"+ questionArray); // Output: [{"question":"Do you enjoy exploring the outdoors?"},{"question":"Do you enjoy reading and researching information?"}]
      // }
      // // const jsonString = JSON.parse(parsedData.match(/\[.*\]/)[0]);
      //const parsedObject = JSON.parse(parsedData);
      let parsedObject;
      if (Array.isArray(parsedData)) {
        parsedObject = { questionList: parsedData };
      } else {
        parsedObject = JSON.parse(parsedData);
      }
      const questionArray = parsedObject.questionList;
      // const finalQ = "questionList"+ JSON.stringify(questionArray);
      console.log(questionArray);

      //console.log("H:userData" + userData);
      dispatch(saveQuestion({ questions: questionArray, userProfile: userData }));
      dispatch(quizStart());
      navigate('/question');
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Something went wrong!");
    }
  }

  return (
    <div className="home-body">
      <div className="flex flex-col items-center">

        {/* <div style={{ marginBottom: "1rem" }}>
          {loading ? (
            <div>
              {(secondsPassed > 40) ? (
                <p>Finalizing all questions, just a moment please while loading!</p>
              ) : (null)
              }
              <CircularProgress
                style={{ height: "30px", width: "30px", margin: 0, padding: 0 }}
              />
            </div>
          ) : (
            <div style={{ height: "33px", width: "33px" }}></div>
          )}
        </div> */}


        {(loading) ? (
          <div className="flex flex-col justify-center items-center h-screen">
            <CircularProgress
              style={{ height: "30px", width: "30px", margin: 0, padding: 0 }}
            />
            {secondsPassed > 25 ? (
              <p className="text-xl font-bold text-center text-blue-600">Finalizing all questions, just a moment please!</p>
            ) : (
              // <p className="text-center">Number of questions generated : {Math.round(secondsPassed * 1.25)}</p>
              <div className="relative pt-1">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <p className="text-xl font-bold text-center text-blue-600"> Generating {Math.round(secondsPassed * 1.25)} Questions </p>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-400">
                  <div
                    style={{ width: `${(Math.round(secondsPassed * 1.25) / 30) * 100}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900"
                  ></div>
                </div>
              </div>
            )}
          </div>
        ) : (

          <form className="bg-slate-100 w-full shadow-md rounded justify-center px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmitButtonClick}>

            {/* <ul className="flex items-center">
              <li>
                <button
                  className="text-red-500 hover:red-700"
                  onClick={() => {
                    // Logout logic here
                    // auth.currentUser.phoneNumber
                    // Reset the state to its initial value
                    //dispatch(state);
                    auth.signOut();
                    // Reload the page after 500ms
                    // setTimeout(() => {
                    //   window.location.reload(false);
                    // }, 1500);
                    dispatch({ type: 'LOGOUT_SUCCESS' });
                  }}
                >
                  Log out
                </button>
              </li>
            </ul> */}

            <p className="text-lg font-bold text-center">Welcome. Tell us a little bit about you.</p>
            <p className="text-sm text-center">This will help us suggest a best career for you.</p>

            <div className="flex justify-between w-2/3 mx-auto mt-8 mb-4">
              <div className="flex flex-col items-center w-full mr-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-center" htmlFor="name">
                  Name:
                </label>
                <input
                  className="shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="John Doe.."
                  value={inputName}
                  onChange={handleInputNameChange}
                />
              </div>
              <div className="flex flex-col items-center w-2/3 ml-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-center" htmlFor="phone">
                  Phone:
                </label>
                <input
                  className="shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="text"
                  placeholder="+8801711..."
                  value={inputPhone}
                  onChange={handleInputPhoneChange}
                />
              </div>
            </div>

            <div className="flex justify-between w-1/2 mx-auto">
              <div className="flex flex-col items-center w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="text1">
                  Age:
                </label>
                <input
                  className="shadow appearance-none border border-black rounded w-full mr-8 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="age"
                  type="text"
                  placeholder="(Ex: 16)"
                  value={inputAge}
                  onChange={handleInputAgeChange}
                />
              </div>

              <div className="flex flex-col items-center w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="text2">
                  Class:
                </label>
                <input
                  className="shadow appearance-none border border-black rounded w-full ml-8 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="class"
                  type="text"
                  placeholder="(Ex: 11/12)"
                  value={inputClass}
                  onChange={handleInputClassChange}
                />
              </div>
            </div>

            <div className='flex flex-col items-center w-full mb-4 mx-auto mt-4'>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Group:
              </label>

              <div className="">
                <label class="inline-flex items-center mr-2 justify-center">
                  <input type="radio" class="form-radio h-5 w-5 text-blue-600" name="subject" value="Science" checked={groupSelected === "Science"} onChange={handleGroupSelectionChange} /><span class="ml-2 text-gray-700">Science</span>
                </label>

                <label class="inline-flex items-center mr-2 justify-center">
                  <input type="radio" class="form-radio h-5 w-5 text-blue-600" name="subject" value="Commerce" checked={groupSelected === "Commerce"} onChange={handleGroupSelectionChange} /><span class="ml-2 text-gray-700">Commerce</span>
                </label>

                <label class="inline-flex items-center mr-2 justify-center">
                  <input type="radio" class="form-radio h-5 w-5 text-blue-600" name="subject" value="Arts" checked={groupSelected === "Arts"} onChange={handleGroupSelectionChange} /><span class="ml-2 text-gray-700">Arts</span>
                </label>

                <label class="inline-flex items-center justify-center">
                  <input type="radio" class="form-radio h-5 w-5 text-blue-600" name="subject" value="None" checked={groupSelected === "None"} onChange={handleGroupSelectionChange} /><span class="ml-2 text-gray-700">N/A</span>
                </label>
              </div>

            </div>

            <div className='flex flex-col items-center w-2/3 mb-4 mx-auto mt-4'>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Likes
                <span className="text-cyan-500"> (Tap to select, Minimum 3)</span>
              </label>

              <input
                className='shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='likes'
                type='text'
                placeholder='Ex: Photography, Entrepreneurship etc.'
                value={inputLikes}
                onChange={handleInputLikesChange}
                onClick={handleClickToOpenLikes} // add onClick event handler
              />
              <Dialog open={open} onClose={handleToCloseLikes}>
                {/* add Dialog component */}
                <DialogTitle>{"Select your likes"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Select minimum 3 or more items from the list below.
                  </DialogContentText>
                  {/* use map function to render checkboxes from an array of options */}
                  {likeDislikeOptions.map(option => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={checkedItems[option] || false}
                          onChange={handleCheckboxChange}
                          name={option}
                          // add disabled prop and set it to true if option is in checkedDislikes
                          disabled={checkedDislikes[option] || false}
                        />
                      }
                      label={option}
                    />
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleToCloseLikes} color="primary" autoFocus>
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="flex flex-col items-center w-2/3 mb-4 mx-auto mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Dislikes
                <span className="text-cyan-500"> (Tap to select, Minimum 3)</span>
              </label>

              <input
                className='shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='dislikes'
                type='text'
                placeholder='Ex: Mathematics, Painting etc.'
                value={inputDislikes}
                onChange={handleInputDislikesChange}
                onClick={handleClickToOpenDislikes}
              />
              <Dialog open={openDislikes} onClose={handleToCloseDislikes}>
                <DialogTitle>{"Select your dislikes"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Select minimum 3 or more items from the list below.
                  </DialogContentText>
                  {/* use map function to render checkboxes from an array of options */}
                  {likeDislikeOptions.map(option => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={checkedDislikes[option] || false}
                          onChange={handleCheckboxChangeDislikes}
                          name={option}
                          // add disabled prop and set it to true if option is in checkedItems
                          disabled={checkedItems[option] || false}
                        />
                      }
                      label={option}
                    />
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleToCloseDislikes} color="primary" autoFocus>
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <button
              type='submit'
              id="btnSubmit"
              className={`flex flex-col items-center w-1/4 mb-4 mx-auto mt-8 border-2 border-black font-bold text-red-500 ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-500 hover:text-white'
                } px-4 py-2`}
              disabled={isDisabled}
            //onClick={handleSubmitButtonClick}
            >
              Start
            </button>
          </form>)}
      </div>


      {/* <div className="home-div">
        <h2 className="text-3xl font-bold underline" style={{ marginTop: "20%", marginBottom: "1rem" }}>
          Input User Profile
        </h2> 
        {/* <p>Generating Question : {secondsPassed}</p>  */}
      {/* {(loading && secondsPassed < 40) ? (
          <p>Number of questions generated : {secondsPassed}</p>
        ) : (null)
        }
        <div style={{ marginBottom: "1rem" }}>
          {loading ? (
            <div>
              {(secondsPassed > 40) ? (
                <p>Finalizing all questions, just a moment please while loading!</p>
              ) : (null)
              }
              <CircularProgress
                style={{ height: "30px", width: "30px", margin: 0, padding: 0 }}
              />
            </div>
          ) : (
            <div style={{ height: "33px", width: "33px" }}></div>
          )}

        </div> */}

      {/* <TextareaAutosize
          id="userProfile"
          className="userProfile"
          name="userProfile"
          onChange={handleChange}
          value={message}
          minRows={15}
          maxRows={20}
          placeholder="Enter text here..."
          style={{
            borderRadius: 10,
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            padding: "1rem",
            fontSize: 15,
            fontFamily: "sans-serif",
          }}
        /> */}
      {/* <div>
          <button
            className="home-button"
            onClick={handleSubmitButtonClick}
            disabled={!message || message.trim() === "" || message.length < 2}
            style={{
              padding: "15px 30px 15px 30px",
              borderRadius: 7,
              borderWidth: 0,
              fontSize: 15,
              backgroundColor: "#e43755",
              cursor: "pointer",
              color: "white",
              fontWeight: "bold",
              marginTop: "2rem",
            }}
          >
            Generate Questions Set
          </button>

        </div> 
      </div> */}
    </div>
  );
};
