// header.js

import React from 'react';
import logo from '../assets/CareerKeyTheme2.png'; // with import
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const step = useSelector((state) => state.step);
  return (
    <header className="flex justify-between items-center p-4 bg-white text-white border-b-2 border-Gray-500">
      <div className="header-left cursor-pointer">
        <img
          src={logo}
          alt="Logo"
          className="max-w-24"
          onClick={() => { if (step !== 2) navigate('/'); }}
        />
      </div>
      <div className="header-right flex">
        <div className="mentorship p-3 border border-[#231842] bg-white rounded mr-4 text-black hover:bg-[#231842] hover:text-white">
          Mentorship
        </div>
        <div className="career-test p-3 border bg-[#231842] rounded bg-[#231842] text-white hover:border-[#231842] hover:bg-white hover:text-black">
          Career Test
        </div>
      </div>
    </header>
  );
};

export default Header;
