// header.js

import React from 'react';
import logo from '../assets/CareerKey_1.png'; // with import
import { useNavigate } from 'react-router-dom';

const HeaderLanding = () => {
  const navigate = useNavigate();
  return (
    <header className="flex justify-between items-center p-4 bg-[#231842] text-white relative">
      <div className="absolute inset-x-0 bottom-0 border-b border-[#FF5466] w-[190vh] mx-auto"></div>
      <img src={logo} alt="Logo" className="w-40 md:w-64 mr-4 md:pl-16 mb-4 md:mb-0" />
      <div className="header-right flex ml-auto space-x-2 md:space-x-4 pr-16">
        <button className="mentorship w-20 h-10 md:w-auto md:h-auto p-1 md:p-3 border border-[#FF5466] rounded text-xs md:text-base hover:bg-[#FF5466]">
          Mentorship
        </button>
        <button className="career-test w-20 h-10 md:w-auto md:h-auto p-1 md:p-3 border border-[#FF5466] rounded bg-[#FF5466] text-xs md:text-base hover:bg-[#231842]"
          onClick={() => navigate('/home')}>
          Career Test
        </button>
      </div>
    </header>
  );
};

export default HeaderLanding;
