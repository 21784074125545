import React from 'react';
import arrow_left from '../assets/angle-left-small.png';
import arrow_right from '../assets/angle-right-small.png';
import image_1 from '../assets/Picture2_1.png';
import image_2 from '../assets/Image_2.png';
import rectangle_red from '../assets/Rectangle_red.png';
import rectangle_black from '../assets/Rectangle_black.png';
import checkbox from '../assets/checkbox.png';
import DIU from '../assets/DIU.png';
import IUB from '../assets/IUB.png';
import UIU from '../assets/UIU.png';
import task from '../assets/task.png';
import teacher from '../assets/teacher.png';
import block from '../assets/block.png';
import image_3 from '../assets/image_3.png';
import image_4 from '../assets/image_4.png';
import star_1 from '../assets/Star_1.png';
import star_2 from '../assets/Star_2.png';
import { GiGraduateCap } from 'react-icons/gi';
import { BiTask } from 'react-icons/bi';
import { ImBriefcase } from 'react-icons/im';

const LandingPage = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-3/5 bg-[#231842] flex flex-col justify-center text-center sm:text-left">
          <h1 className="text-6xl font-bold text-white px-8 sm:pl-20 mb-4">Career <br /> Guidelines</h1>
          <p className="text-xl text-white px-8 sm:pl-20 mb-4">Career guidance is the guidance given to individuals<br />
          to help them acquire the knowledge, information, <br />skills, and experience necessary.</p>
        <div className="hidden sm:flex justify-start items-center px-8 sm:pl-20 mb-4">
        {/* Arrows always below the text */}
          <img src={arrow_left} alt="Left Arrow" className="max-w-24 mr-5 sm:mb-0" />
          <img src={arrow_right} alt="Right Arrow" className="max-w-24" />
        </div>
      </div>
        <div className="w-full sm:w-2/5 text-center bg-[#231842] pt-4 pb-4">
          <img src={image_1} alt="Image 1" className="w-5/6 h-auto mb-4 mx-auto" />
        {/* Arrows always below the image */}
        <div className="flex justify-center items-center mb-4 sm:hidden">
          <img src={arrow_left} alt="Left Arrow" className="max-w-24 mr-5" />
          <img src={arrow_right} alt="Right Arrow" className="max-w-24" />
        </div>
      </div>
    </div>

    <div className="flex flex-row justify-between bg-white items-center px-4 sm:px-20 py-10 sm:py-20">
      <div className="border-r border-[#BBB7C4] px-4 sm:px-8 pl-4 sm:pl-60 text-center w-1/3 sm:w-3/5 py-4">
        <div className="w-10 sm:w-20 h-10 sm:h-20 bg-[#D5D2DA] rounded-full mx-auto mb-2 sm:mb-4 flex items-center justify-center">
          <GiGraduateCap className="text-white text-3xl sm:text-5xl" />
        </div>
        <h2 className="text-2xl sm:text-4xl text-black font-bold mb-1 sm:mb-2">500+</h2>
        <p className="text-lg sm:text-xl text-black">Subjects to Study</p>
      </div>
      <div className="border-r border-[#BBB7C4] px-4 sm:px-8 text-center w-1/3 sm:w-3/5 py-4">
        <div className="w-10 sm:w-20 h-10 sm:h-20 bg-[#FF5466] rounded-full mx-auto mb-2 sm:mb-4 flex items-center justify-center">
          <BiTask className="text-white text-3xl sm:text-5xl" />
        </div>
        <h2 className="text-2xl sm:text-4xl text-black font-bold mb-1 sm:mb-2">4.5K</h2>
        <p className="text-lg sm:text-xl text-black">Test Completed</p>
      </div>
      <div className="px-4 sm:px-8 pr-4 sm:pr-60 text-center w-1/3 sm:w-3/5 py-4">
        <div className="w-10 sm:w-20 h-10 sm:h-20 bg-[#D5D2DA] rounded-full mx-auto mb-2 sm:mb-4 flex items-center justify-center">
          <ImBriefcase className="text-white text-3xl sm:text-5xl" />
        </div>
        <h2 className="text-2xl sm:text-4xl text-black font-bold mb-1 sm:mb-2">13M</h2>
        <p className="text-lg sm:text-xl text-black">Job Post Analyzed</p>
      </div>
    </div>

    <div className="flex flex-col sm:flex-row">
      <div className="w-full sm:w-3/5 bg-[#D5D2DA] flex flex-col items-center sm:items-start">
        <div className="flex items-center pl-8 lg:pl-20 mb-4 mt-4 sm:mt-8">
          <img src={rectangle_red} alt="Rectangle" className="w-3 h-3 mr-2 mt-4 sm:mt-8" />
          <p className="text-xl mt-4 sm:mt-8 text-black font-bold">Why choose us?</p>
        </div>
        <h1 className="text-5xl text-center sm:text-left font-bold text-[#231842] pl-8 lg:pl-20 mb-4">We Provide Solutions To<br /> Choose Your Career</h1>
        <p className="text-xl text-black text-center sm:text-left pl-8 lg:pl-20 mb-4">This is a beautiful template! The custom page builder is <br />definitely
          most intuitive. This is a beautiful template! The <br /> custom page builder is </p>
        <div className="flex items-center pl-8 lg:pl-20 mb-4">
          <img src={checkbox} alt="Checkox" className="w-5 h-5 mr-2" />
          <p className="text-xl text-black font-bold">Career Test</p>
        </div>
        <div className="flex items-center pl-8 lg:pl-20 mb-4">
          <img src={checkbox} alt="Checkbox" className="w-5 h-5 mr-2" />
          <p className="text-xl text-black font-bold">Mentorship</p>
        </div>
    </div>
    <div className="w-full sm:w-2/5 relative">
      <img src={image_2} alt="Image 2" className="w-full h-full sm:absolute sm:inset-0 object-cover" />
    </div>
   </div>


   <div className="flex flex-col items-center justify-center h-full sm:h-[70vh]">
      <div className="flex items-center justify-center mb-4 text-center font-bold text-2xl text-black">
        <img src={rectangle_black} alt="Rectangle" className="w-3 h-3 mr-2" />
        <p>Our Trusted Customer</p>
      </div>
      <div className="mb-4 font-bold text-center text-4xl text-[#FF5466]">
        <p>We Have A Wide Range Of Customers</p>
      </div>
      <div className="flex flex-wrap justify-center mt-6 space-x-2 sm:space-x-20">
        <img src={DIU} alt="DIU" className="w-16 sm:w-auto mb-4" />
        <img src={UIU} alt="UIU" className="w-16 sm:w-auto mb-4" />
        <img src={DIU} alt="DIU" className="w-16 sm:w-auto mb-4" />
        <img src={IUB} alt="IUB" className="w-16 sm:w-auto mb-4" />
        <img src={DIU} alt="DIU" className="w-16 sm:w-auto mb-4" />
      </div>
    </div>


    <div className="flex flex-col bg-[#D5D2DA] items-center justify-center h-full px-4 md:px-0">
  <div className="flex items-center justify-center mb-4 text-center font-bold text-xl md:text-2xl text-black">
    <img src={rectangle_red} alt="Rectangle" className="w-3 h-3 mr-2" />
    <p>Work Progress</p>
  </div>
  <div className="mb-1 md:mb-4 font-bold text-center text-2xl md:text-4xl text-[#231842]">
    <p>How We Work</p>
  </div>
  <div className="flex flex-col md:flex-row mt-10 justify-around space-y-9 md:space-y-0 md:space-x-8 mb-4">
    <div className="w-full md:w-[65vh] h-full md:h-[45vh] bg-[#231842] p-4 rounded-lg relative">
      <div className="w-16 md:w-20 h-16 md:h-20 rounded-full bg-[#FF5466] absolute -top-8 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
        <img src={task} alt="Task" className="w-10 md:w-12 h-10 md:h-12 object-cover" />
      </div>
      <p className="mt-5 md:mt-10 text-center font-bold text-white text-sm md:text-xl">Psychometric Test</p>
      <p className="mt-2 md:mt-4 text-center text-white text-sm md:text-xl">AI Powered two steps <br />psychometric test to measure <br /> the potential ability</p>
      <p className="mt-4 text-center font-bold text-[#FF5466] text-sm md:text-xl">Learn more</p>
    </div>
    <div className="w-full md:w-[65vh] h-full md:h-[45vh] bg-[#FF5466] p-4 rounded-lg relative">
      <div className="w-16 md:w-20 h-16 md:h-20 rounded-full bg-[#231842] absolute -top-8 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
        <img src={block} alt="Block" className="w-10 md:w-12 h-10 md:h-12 object-cover" />
      </div>
      <p className="mt-5 md:mt-10 text-center font-bold text-white text-sm md:text-xl">Solutions</p>
      <p className="mt-4 text-center text-white text-sm md:text-xl">best Career options will be<br /> suggested based on the<br /> psychometric test result.</p>
      <p className="mt-4 text-center font-bold text-white text-sm md:text-xl">Learn more</p>
    </div>
    <div className="w-full md:w-[65vh] h-full md:h-[45vh] bg-[#231842] p-4 rounded-lg relative mb-5 md:mb-0">
      <div className="w-16 md:w-20 h-16 md:h-20 rounded-full bg-[#FF5466] absolute -top-8 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
        <img src={teacher} alt="Teacher" className="w-10 md:w-12 h-10 md:h-12 object-cover" />
      </div>
      <p className="mt-5 md:mt-10 text-center font-bold text-white text-sm md:text-xl">On Demand Mentorship</p>
      <p className="mt-4 text-center text-white text-sm md:text-xl">To leverage the confusion and <br /> to get more insights, CareerKey<br /> offers personal counselling.</p>
      <p className="mt-4 text-center font-bold text-[#FF5466] text-sm md:text-xl">Learn more</p>
    </div>
  </div>
</div>

<div className="flex flex-col sm:flex-row h-full">
  <div className="w-full sm:w-3/5 bg-white flex flex-col justify-center text-left mt-2 sm:mt-0">
    <div className="flex items-center justify-center sm:justify-start lg:pl-20 mb-6 mt-0 sm:mt-2">
      <img src={rectangle_red} alt="Rectangle" className="w-3 h-3 mr-2" />
      <p className="text-lg sm:text-xl text-black font-bold">Testimonial</p>
    </div>

    <h1 className="text-4xl sm:text-5xl text-2xl font-bold text-[#FF5466] lg:pl-20 mb-4 text-center sm:text-left">What Our <br /> CLient Say's</h1>
    <p className="text-lg sm:text-xl text-[#828282] lg:pl-20 sm:mb-10 mb-4 text-center sm:text-left">This is a beautiful template! The  <br /> custom page builder is definitely
      <br /> most intuitive. </p>
    <div className="flex hidden sm:flex justify-center sm:justify-start items-center pl-8 lg:pl-20 sm:mb-8 mb-0">
      <img src={arrow_left} alt="Left Arrow" className="max-w-24 mr-5" />
      <img src={arrow_right} alt="Right Arrow" className="max-w-24" />
    </div>
  </div>
  
  <div className="flex justify-center sm:justify-start mb-4">
    <div className="w-full sm:w-1/3 mx-auto sm:mx-0">
        <div className="w-5/6 sm:w-[65vh] sm:h-[60vh] bg-[#FFEEF0] p-4 rounded-lg relative sm:mt-20 mt-0 sm:transform transform-0 sm:-translate-x-10 -translate-x-0 mx-auto">
          <div className="flex">
            <div className="-3/5">
              <img src={image_3} alt="Girl Image" className="w-16 h-16 object-cover rounded-full" />
            </div>
            <div className="w-1/2 flex flex-col items-start pl-2">
              <p>Natasha Kelvin</p>
              <p>Designer</p>
              <img src={star_1} alt="Star Red" className="w-25 h-6" />
            </div>
          </div>
          <div className="flex justify-center">
            <p className="mt-6 text-left inline-block">" dd an excerpt from your personal<br /> biography, or simply let the world<br />
              know who you are and what you <br /> have to offer. Connect with your<br />
              site visitor's on a personal level and<br /> make sure that your site.</p>
          </div>
        </div>
      </div>
</div>


<div className="flex justify-center sm:justify-start">
    <div className="w-full sm:w-1/3 mx-auto sm:mx-0 sm:mt-0 mt-10">
        <div className="w-5/6 sm:w-[65vh] sm:h-[40vh] border border-[#BBB7C4] bg-white p-8 rounded-lg relative sm:mt-20 mb-8 sm:transform transform-0 sm:-translate-x-5 -translate-x-0 mx-auto">
          <div className="flex">
            <div className="-3/5">
              <img src={image_4} alt="Girl Image 2" className="w-16 h-16 object-cover rounded-full" />
            </div>
            <div className="w-1/2 flex flex-col items-start pl-2">
              <p>Cincinnatia</p>
              <p>Designer</p>
              <img src={star_2} alt="Star Yellow" className="w-25 h-6" />
            </div>
          </div>
          <div className="flex justify-center">
            <p className="mt-6 text-[#828282] text-left inline-block">" Leverage agile frameworks to <br /> provide a robust synopsis for high <br /> level overviews "</p>
          </div>
        </div>
      </div>

      
  </div>
  {/* Arrows always below the image */}
  <div className="flex justify-center items-center mb-4 sm:hidden">
            <img src={arrow_left} alt="Left Arrow" className="max-w-24 mr-5" />
            <img src={arrow_right} alt="Right Arrow" className="max-w-24" />
          </div>

  </div>

      </div>
    );
  };

  export default LandingPage;
