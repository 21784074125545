import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
 
const firebaseConfig = {
    // Your credentials
    apiKey: "AIzaSyBzkGof1Y-FOrcK0TbVKZ6b0geBjmGinxg",
    authDomain: "careerkey-68794.firebaseapp.com",
    projectId: "careerkey-68794",
    storageBucket: "careerkey-68794.appspot.com",
    messagingSenderId: "774194190748",
    appId: "1:774194190748:web:f56e4b58f7c5ad808a1059",
    measurementId: "G-BY3K8WHQ90"
};
 
firebase.initializeApp(firebaseConfig);
//let auth = firebase.auth();
const database = firebase.database();
export default database;