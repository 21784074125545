import React from "react";

function Card({ item, index }) {
    return (
        <div className="w-full rounded overflow-hidden shadow-lg mt-2 mb-2 transform hover:-translate-y-1 hover:scale-110 transition duration-500 ease-in-out border-2 border-blue-500">
            <div className="px-4 py-4">
                <div className="font-bold text-xl mb-2">Career Option #{index + 1}: {item.careerOption }</div>

                <div className={`pt-4 pb-2`}>
                    <span className={`inline-block rounded-full px-3 py-2 text-sm font-semibold text-gray-700 mr-2 ${item.matchingScore > 0.65 ? 'bg-green-400' : 'bg-yellow-300'}`}>
                        Matching Score: {item.matchingScore}
                    </span>
                </div>

                <div className="pt-4 font-bold text-l mb-2">Character Traits:</div>
                <p className="text-gray-700 text-base">{item.characterTraits}</p>
                <div className="pt-4 font-bold text-l mb-2">Reasons to choose:</div>
                <p className="text-gray-700 text-base">{item.explanation}</p>
                <div className="pt-4 font-bold text-l mb-2">Subject Major:</div>
                <p className="text-gray-700 text-base">{item.subjectMajor}</p>
                <div className="pt-4 font-bold text-l mb-2">Why this Subject?</div>
                <p className="text-gray-700 text-base">{item.majorExplanation}</p>
            </div>
        </div>
    );
}

// function CardView({ suggestion }) {
//     const [currentCardIndex, setCurrentCardIndex] = useState(0);

//     const handlePreviousClick = () => {
//         if (currentCardIndex > 0) {
//             setCurrentCardIndex(currentCardIndex - 1);
//         }
//     };

//     const handleNextClick = () => {
//         if (currentCardIndex < suggestion.length - 1) {
//             setCurrentCardIndex(currentCardIndex + 1);
//         }
//     };

//     return (
//         <div className="flex flex-wrap justify-center w-full">
//             <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-l" onClick={handlePreviousClick}>
//                 Previous
//             </button>
//             <div className="flex flex-col">
//                 <div className="w-full">
//                     {suggestion &&
//                         suggestion.map((item) => (
//                             <Card key={suggestion[currentCardIndex].careerOption} item={suggestion[currentCardIndex]} />
//                         ))}                
//                 </div>
//                 {/* {suggestion &&
//                     suggestion.map((item) => (
//                         <Card key={suggestion[currentCardIndex].careerOption} item={suggestion[currentCardIndex]} />
//                     ))} */}
//             </div>
//             <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r" onClick={handleNextClick}>
//                 Next
//             </button>
//         </div>

//         // <div className="flex flex-col items-center justify-center">
//         //     <div className="flex justify-between w-full">
//         //         <button
//         //             className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" ${currentCardIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
//         //                 }`}
//         //             onClick={handlePreviousClick}
//         //             disabled={currentCardIndex === 0}
//         //         >
//         //             <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
//         //                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1M1 5L5 1M1 5L5 9" />
//         //             </svg>
//         //             {/* Previous */}
//         //         </button>
//         //         {/* <div className="flex justify-center items-center">
//         //             <div
//         //                 key={currentCardIndex}
//         //                 className={`max-w-sm rounded overflow-hidden shadow-lg m-4 transform transition duration-500 ease-in-out`}
//         //                 style={{
//         //                     width: "100%",
//         //                     display: "flex",
//         //                     justifyContent: "center",
//         //                     alignItems: "center",
//         //                 }}
//         //             >
//         //                 <div style={{ width: "100%" }}>
//         //                     {suggestion &&
//         //                     suggestion.map((item) => (
//         //                         <Card item={suggestion[currentCardIndex]} />
//         //                     ))}
//         //                 </div>
//         //             </div>
//         //         </div> */}
//         //         <button
//         //             className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${currentCardIndex === suggestion.length - 1 ? "opacity-50 cursor-not-allowed" : ""
//         //                 }`}
//         //             onClick={handleNextClick}
//         //             disabled={currentCardIndex === suggestion.length - 1}
//         //         >
//         //             <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
//         //                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
//         //             </svg>
//         //             {/* Next */}
//         //         </button>
//         //     </div>
//         //     <div className="flex justify-between items-center">
//         //         <div
//         //             key={currentCardIndex}
//         //             className={`max-w-sm rounded overflow-hidden shadow-lg m-4 transform transition duration-500 ease-in-out`}
//         //             style={{
//         //                 width: "100%",
//         //                 display: "flex",
//         //                 justifyContent: "center",
//         //                 alignItems: "center",
//         //             }}
//         //         >
//         //             <div style={{ width: "100%" }}>
//         //                 <Card item={suggestion[currentCardIndex]} />
//         //                 {/* {suggestion &&
//         //                     suggestion.map((item) => (
//         //                         <Card item={suggestion[currentCardIndex]} />
//         //                     ))} */}
//         //                 {/* <div className="flex justify-between w-full">
//         //                     <button
//         //                         className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${currentCardIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
//         //                             }`}
//         //                         onClick={handlePreviousClick}
//         //                         disabled={currentCardIndex === 0}
//         //                     >
//         //                         Previous
//         //                     </button>
//         //                     <button
//         //                         className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${currentCardIndex === suggestion.length - 1 ? "opacity-50 cursor-not-allowed" : ""
//         //                             }`}
//         //                         onClick={handleNextClick}
//         //                         disabled={currentCardIndex === suggestion.length - 1}
//         //                     >
//         //                         Next
//         //                     </button>
//         //                 </div>  */}
//         //             </div>
//         //         </div>
//         //     </div>
//         // </div>
//     );
// }

export default Card;
